import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import capitalize from '@/services/capitalize';
import styles from './index.module.scss';
import Link from '@/components/atoms/Link';

function objectToArrayOfLinks(object, lan) {
  // Array donde estarán todos los elementos que irán en el breadcrumb
  const array = [];
  const normalizeMessage = (text) =>
    text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  // Texto que se mostrará en el breadcrumb
  const visibleText = (text) => {
    switch (text) {
      case 'nino':
        return 'Niño';
      case 'munequeras-vinchas':
        return 'Muñequeras Vinchas';
      case 'futbol':
        return 'Fútbol';
      case 'natacion':
        return 'Natación';
      case 'botines-multitapon':
        return 'Botines Multitapón';
      case 'patin-patineta':
        return 'Patín Patineta';
      case 'adidas':
        return 'adidas';
      case 'adidas-running':
        return 'adidas Running';
      default:
        return capitalize(text, '-');
    }
  };

  const transformText = (text) =>
    text
      ?.toLowerCase()
      .split(' ')
      .map((x) => normalizeMessage(x))
      .join('-');
  if (lan === 'en') {
    object = {
      gender: transformText(object.gender),
      slugs: [transformText(object.category_name)],
      marca: [transformText(object.brand_name)],
      deporte: [transformText(object.sport)],
      categoria: transformText(object.business),
      landing: transformText(object.landing)
    };
  }
  // Verifica si existe y hace el push
  const pushIfIsActive = (arg, arr) => {
    if (Array.isArray(arg)) {
      if (typeof arg[0] !== 'string' && arg[0]?.props.children.props.children)
        arr.push(...arg);
      else if (arg[0] && typeof arg[0] === 'string') arr.push(...arg);
    } else if (arg) return object[arg]?.length > 0 ? arr.push(object[arg]) : '';
    return 1;
  };
  pushIfIsActive('gender', array);
  pushIfIsActive('categoria', array);
  pushIfIsActive(object.slugs, array);
  pushIfIsActive(object.marca, array);
  pushIfIsActive(object.deporte, array);
  const arrayOfLinks = array.map((e, i) => (
    <Link
      key={e}
      href={`/${array.slice(0, i + 1).join('/')}/p/1`}
      className={styles.link}
    >
      {visibleText(e)}
    </Link>
  ));
  arrayOfLinks.unshift(
    <Link
      key="inicio"
      href="/"
      className={styles.link}
    >
      Inicio
    </Link>
  );

  if (arrayOfLinks.length >= 1) {
    arrayOfLinks.splice(
      1,
      0,
      <Link
        key="productos"
        href="/productos/p/1"
        className={styles.link}
      >
        Productos
      </Link>
    );
    if (arrayOfLinks[2]?.props.href.pathname === '') {
      arrayOfLinks[2].props.href.pathname = 'productos';
    }
  }
  return arrayOfLinks;
}

const Breadcrumb = ({ aplicateIn = '', style = {} }) => {
  const [brcrumb, setBrcrumb] = useState([]);
  const filtersActive = useSelector((state) =>
    aplicateIn === 'productDetail'
      ? state.productDetail
      : state.products.filtersActive
  );

  const classMobile = cn({
    [styles.breadcrumb]: true
  });
  const router = useRouter();
  useEffect(() => {
    setBrcrumb(
      objectToArrayOfLinks(
        filtersActive,
        aplicateIn === 'productDetail' ? 'en' : 'es',
        router.asPath
      )
    );
  }, [filtersActive]);

  const breadcrumbHtml = () => (
    <nav>
      <ol
        className={classMobile}
        style={style}
      >
        {brcrumb.map((b, i) => (
          <li
            className={styles.item}
            key={b.key}
          >
            {b}
            {i < brcrumb.length - 1 && (
              <div className={styles.chevron}>
                <FiChevronDown
                  color="white"
                  fontSize={16}
                  style={{ transform: 'rotate(270deg)' }}
                />
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
  return <>{breadcrumbHtml()}</>;
};

Breadcrumb.propTypes = {
  aplicateIn: PropTypes.string,
  style: PropTypes.object
};

export default Breadcrumb;

import PropTypes from 'prop-types';
import styles from './galleryProduct.module.scss';
import Pagination from '@/components/molecules/Pagination';

const GalleryProduct = ({
  children,
  margin = 0,
  pagination = {},
  style = {}
}) => (
  <div
    className={styles.container}
    style={{ margin, ...style }}
  >
    {/* TODO: Hacer skeleton de los productos */}
    <ul
      id="grid-container"
      className={styles['grid-container']}
    >
      {children}
    </ul>
    {pagination.total && <Pagination pagination={pagination} />}
  </div>
);

GalleryProduct.propTypes = {
  children: PropTypes.node.isRequired,
  pagination: PropTypes.object,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object
};

export default GalleryProduct;
